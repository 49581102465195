import { isMeeting } from "@copernicsw/community-common";
import Service from "@/config/service-identifiers";
import store from "@/store";

/**
 * Event Store Module
 */
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchEvent(ctx, { eventKey, ...config }) {
      const response = await store.$service[Service.BackendClient].get(
        `events/${eventKey}`,
        {
          params: {
            communityKey: ctx.rootGetters.currentCollective.key,
            locationsCount: 1,
            ...config,
          },
        }
      );

      // Used to add back to copernicData Store the events. It will be removed once copernicData is eliminated.
      ctx.commit(
        "ADD_UNPAGINATED_ITEMS",
        { type: "events", items: response.data.data },
        { root: true }
      );

      return response.data ? response.data.data[0] || null : null;
    },

    async fetchEventList(ctx, queryParams) {
      const response = await store.$service[Service.BackendClient].get(
        "events",
        {
          params: {
            communitySlug: queryParams.communitySlug,
            communityKey: queryParams.communityKey,
            visibleOncommunityKey: queryParams.visibleOncommunityKey,
            visibleOncommunitySlug: queryParams.visibleOncommunitySlug,

            sinceStartDate: queryParams.sinceStartDate,
            untilStartDate: queryParams.untilStartDate,
            sinceEndDate: queryParams.sinceEndDate,
            untilEndDate: queryParams.untilEndDate,

            isSubscribed: queryParams.isSubscribed,

            classifiers: queryParams.classifiers,
            customfields: queryParams.customfields,

            states: queryParams.states,
            speakersCount: queryParams.speakersCount,
            count: queryParams.count,
            offset: queryParams.offset,
            page: queryParams.page,
            orderByDate: queryParams.orderByDate,
            excludeTypes: queryParams.excludeTypes,
            includeTypes: queryParams.includeTypes,
            excludeStreaming: queryParams.excludeStreaming,
            locationsCount: queryParams.locationsCount, // FIXME: Use pagination
            attendeersCount: queryParams.attendeersCount, // FIXME: Use pagination
            eventKeys: queryParams.eventKeys,
            eventPackage: queryParams.eventPackage,
            peopleKey: queryParams.peopleKey,
            classifiers: queryParams.classifiers,
            customFields: queryParams.customFields,
          },
        }
      );

      // Used to add back to copernicData Store the events. It will be removed once copernicData is eliminated.
      ctx.commit(
        "ADD_UNPAGINATED_ITEMS",
        { type: "events", items: response.data.data },
        { root: true }
      );

      return response;
    },
    /**
     * Attempts subscribe the current user to an event. Response is succesful if contains subscriptionRequested
     *
     * @param {Object} ctx Context object
     * @param {String} eventKey Key of the event to subscribe
     * @returns {{
     *   subscriptionRequested: boolean, // This key appears when the subscription executed correctly. When true,
     *                                  // organization must approve it. false if no further action is required.
     *   invitationNeeded?: boolean, // When true, the subscription wasn't succesful and indicates that the event
     *                               // has accessibility InviteOnly
     *   eventDoesntExist?: boolean, // When true, the subscription wasn't succesful and indicates that the key for
     *                               // the event doesn't exist
     *   eventFinished?: boolean, // When true, the subscription wasn't succesful and indicates that the event already
     *                            // finished
     *   eventInscriptionClosed?: boolean, // When true, the subscription wasn't succesful and indicates that the event
     *                                     // has enrollment dates and it's outside them
     *   eventCanceled?: boolean, // When true, the subscription wasn't succesful and indicates that the event is
     *                            // canceled
     *   eventHasntPlaces?: boolean // When true, the subscription wasn't succesful and indicates that the event is
     *                              // already hit the maximum of places avaiable
     * }}
     */
    async subscribeEvent(ctx, { eventKey, attendance, communityKey }) {
      return store.$service[Service.BackendClient].post("/subscribeEvent", {
        eventKey,
        attendance,
        communityKey,
      });
    },

    /**
     * Attempts unsubscribe the current user from an event. Response is succesful if is empty
     *
     * @param {Object} ctx Context object
     * @param {String} eventKey Key of the event to subscribe
     * @returns {{
     *   isntSubscribed?: boolean, // When true, the unsubscription wasn't succesful and indicates that the user
     *                             // wasn't subscribed to this event
     *   eventDoesntExist?: boolean, // When true, the subscription wasn't succesful and indicates that the key for
     *                               // the event doesn't exist
     *   eventFinished?: boolean, // When true, the subscription wasn't succesful and indicates that the event already
     *                            // finished
     *   eventInscriptionClosed?: boolean, // When true, the subscription wasn't succesful and indicates that the event
     *                                     // has enrollment dates and it's outside them
     *   eventCanceled?: boolean, // When true, the subscription wasn't succesful and indicates that the event is
     *                            // canceled
     * }}
     */
    async unsubscribeEvent(ctx, { eventKey, attendance, communityKey }) {
      return store.$service[Service.BackendClient].post("/unsubscribeEvent", {
        eventKey,
        attendance,
        communityKey,
      });
    },
    /**
     * Adds a new event.
     * If the calendary type is meeting `POST /createMeeting` end-point is used.
     * Otherwise it fails.
     */
    async createMeeting(
      _ctx,
      {
        locale,
        collectiveKey,
        organizationKey,
        withShared,
        showCreated,
        eventType,
        event, // TODO: Calendar Refactor Do not use full-calendar events in the store.
      }
    ) {
      try {
        return store.$service[Service.BackendClient].post("/createMeeting", {
          name: { [locale]: event.title },
          description: { [locale]: event.extendedProps.description },
          locations: event.extendedProps.location
            ? [event.extendedProps.location]
            : [],
          allDay: event.extendedProps.allDay,
          withShared,
          showCreated,
          eventType: eventType != null ? eventType : undefined,
          startDate: new Date(event.start).getTime() / 1000,
          endDate: event.end ? new Date(event.end).getTime() / 1000 : null,
          invitedPeople: (event.extendedProps.guests || []).map(
            ({ userKey }) => userKey
          ),
          ...(organizationKey ? { organizationKey } : { collectiveKey }),
          isMeeting: isMeeting.Meeting,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async createEvent(
      _ctx,
      {
        locale,
        collectiveKey,
        withShared,
        eventType,
        event,
        showCreated, // TODO: Calendar Refactor Do not use full-calendar events in the store.
      }
    ) {
      const requestData = {
        name: { [locale]: event.name },
        description: { [locale]: event.description },
        withShared,
        eventType,
        startDate: new Date(event.start).getTime() / 1000,
        endDate: event.end ? new Date(event.end).getTime() / 1000 : null,
        collectiveKey,
        isMeeting: isMeeting.Event,
        locations: [],
        modality: event.modality ?? 1,
        accessibility: event.accessibility,
        showCreated,
      };
      if (event.thumbnailURL) {
        const formData = new FormData();
        formData.append("file", event.thumbnailURL);
        formData.append("data", JSON.stringify(requestData));

        await store.$service[Service.BackendClient].post(
          "createMeeting",
          formData,
          { headers: { "content-type": "multipart/form-data" } }
        );
      } else {
        await store.$service[Service.BackendClient].post(
          "createMeeting",
          requestData,
          { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        );
      }
      const result = {
        title: { [locale]: event.name },
        description: { [locale]: event.description },
        startDate: new Date(event.start).getTime() / 1000,
        endDate: event.end ? new Date(event.end).getTime() / 1000 : null,
      };
      _ctx.commit(
        "ADD_ITEM",
        { itemType: "events", data: result },
        { root: true }
      );
    },
    /**
     * Cancels the given event.
     */
    async cancelEvent(_ctx, { key }) {
      return store.$service[Service.BackendClient].post("/cancelEvent", {
        key,
      });
    },

    /**
     * Updates the given event.
     */
    async updateEvent(_ctx, { locale, event }) {
      // TODO: Calendar Refactor Do not use full-calendar events in the store
      // Does not support invitedPeople
      return store.$service[Service.BackendClient].put("/modifyEvent", {
        key: event.id,
        title: { [locale]: event.title },
        description: { [locale]: event.extendedProps.description },
        startDate: new Date(event.start).getTime(),
        endDate: event.end ? new Date(event.end).getTime() : null,
        allDay: event.extendedProps.allDay,
        modality: event.modality,
          // event.extendedProps.modality === "on-site"
          //   ? 0
          //   : event.extendedProps.modality === "online"
          //   ? 1
          //   : 2,
        locations: event.extendedProps?.location
          ? [event.extendedProps.location]
          : [],
      });
    },
    async editEvent(_ctx, { event, showCreated }) {
      // TODO: Calendar Refactor Do not use full-calendar events in the store
      const requestData = {
        key: event.id,
        title: event.title,
        description: event.description,
        startDate: event.startDate,
        endDate: event.endDate,
        allDay: event.allDay,
        location: event.location,
        modality: event.modality,
        accessibility: event.accessibility,
        showCreated,
        poweredByKey: event.poweredByKey,
        poweredByType: event.poweredByType,
        deleteImage: event.deleteImage,
        /* isMeeting: isMeeting.Event, */
      };
      if (event.thumbnailURL && !event.deleteImage) {
        const formData = new FormData();
        formData.append("file", event.thumbnailURL);
        formData.append("data", JSON.stringify(requestData));

        await store.$service[Service.BackendClient].put(
          "/modifyEvent",
          formData,
          { headers: { "content-type": "multipart/form-data" } }
        );
      } else {
        await store.$service[Service.BackendClient].put("/modifyEvent", {
          key: event.id,
          title: event.title,
          description: event.description,
          startDate: event.startDate,
          endDate: event.endDate,
          allDay: event.allDay,
          location: event.location,
          modality: event.modality ?? 1,
          accessibility: event.accessibility,
          showCreated,
          poweredByKey: event.poweredByKey,
          poweredByType: event.poweredByType,
          deleteImage: event.deleteImage,
        });
      }
    },
    async inviteGuests(_ctx, { eventKey, userKeys }) {
      return store.$service[Service.BackendClient].post("/inviteAttendeers", {
        eventKey,
        userKeys,
      });
    },
    async removeGuests(_ctx, { eventKey, userKeys }) {
      return store.$service[Service.BackendClient].post("/removeAttendeers", {
        eventKey,
        userKeys,
      });
    },
    async acceptInvitation(_ctx, { eventKey }) {
      return store.$service[Service.BackendClient].post("/acceptInvitation", {
        eventKey,
      });
    },
    async declineInvitation(_ctx, { eventKey, reason }) {
      return store.$service[Service.BackendClient].post("/declineInvitation", {
        eventKey,
        reason,
      });
    },
    async maybeInvitation(_ctx, { eventKey, reason }) {
      return store.$service[Service.BackendClient].post("/maybeInvitation", {
        eventKey,
        reason,
      });
    },
  },
};
