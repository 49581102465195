export const EVENTS_STORE_MODULE_NAME = 'event';

export const EVENTS_ACTIONS = {
  fetchEventList: `${EVENTS_STORE_MODULE_NAME}/fetchEventList`,
  fetchEvent: `${EVENTS_STORE_MODULE_NAME}/fetchEvent`,
  subscribeEvent: `${EVENTS_STORE_MODULE_NAME}/subscribeEvent`,
  unsubscribeEvent: `${EVENTS_STORE_MODULE_NAME}/unsubscribeEvent`,

  createMeeting: `${EVENTS_STORE_MODULE_NAME}/createMeeting`,
  createEvent: `${EVENTS_STORE_MODULE_NAME}/createEvent`,
  cancelEvent: `${EVENTS_STORE_MODULE_NAME}/cancelEvent`,
  updateEvent: `${EVENTS_STORE_MODULE_NAME}/updateEvent`,
  editEvent: `${EVENTS_STORE_MODULE_NAME}/editEvent`,

  inviteGuests: `${EVENTS_STORE_MODULE_NAME}/inviteGuests`,
  removeGuests: `${EVENTS_STORE_MODULE_NAME}/removeGuests`,

  acceptInvitation: `${EVENTS_STORE_MODULE_NAME}/acceptInvitation`,
  maybeInvitation: `${EVENTS_STORE_MODULE_NAME}/maybeInvitation`,
  declineInvitation: `${EVENTS_STORE_MODULE_NAME}/declineInvitation`,
};
